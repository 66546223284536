export const AVATAR_FITS = {
  cover: 'cover',
  contain: 'contain',
} as Record<string, string>;

export const AVATAR_SHAPES = {
  circle: '50%',
  square: '.5rem',
} as Record<string, string>;

export const AVATAR_SIZES = {
  xs: '2rem',
  sm: '3rem',
  md: '4rem',
  lg: '5rem',
} as Record<string, string>;
