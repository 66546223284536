import clsx from 'clsx';
import { XORShift } from 'random-seedable';
import { FC, ImgHTMLAttributes, useEffect, useState } from 'react';

import { COLORS } from '../../variables';
import type { Color, Fit, Shape, Size } from '../types';
import * as S from './styles';
import { getInitials, getInitialsSize, hashString } from './utils';

export type AvatarProps = {
  alt?: string;
  className?: string;
  shape?: Shape;
  size?: Size;
  fit?: Fit;
  variant?: Color;
  imgLoading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
  src?: string;
  avatarUiName?: string;
  autoInitials?: boolean;
  shadow?: boolean;
  border?: boolean;
};

export type SupportedColors = Required<AvatarProps['variant']> | null;

const supportedColors = ['success', 'warning', 'danger', 'primary', 'secondary', 'client', 'agency'] as const;

const DefaultIcon: FC<AvatarProps> = (props: AvatarProps) => {
  const { alt, className, shape, size, fit, shadow, border } = props;

  return (
    <S.AvatarSvg
      shadow={shadow}
      width="320px"
      height="320px"
      viewBox="0 0 320 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={alt}
      className={clsx(
        className,
        size ? `avatar-${size}` : null,
        shape ? `avatar-${shape}` : null,
        fit ? `avatar-${fit}` : null,
        shadow ? 'shadow-2' : border ? 'border border-secondary-200' : null,
        'avatar',
      )}>
      {alt && <title id={alt}>{alt}</title>}
      <rect width="320" height="320" fill={COLORS.SECONDARY_100} />
      <path
        d="M87.0557 222.4L98.9117 204.112C111.456 213.248 126.288 218.512 139.296 218.512C151.84 218.512 159.376 213.264 159.376 205.024C159.376 195.664 148.432 192.464 134.288 188.8C108.736 181.936 92.5437 174.864 92.5437 153.376C92.5437 131.44 110.56 116.352 137.024 116.352C152.992 116.352 169.184 122.064 181.968 130.528L171.248 149.264C160.064 142.176 147.744 137.6 137.024 137.6C126.528 137.6 118.32 142.4 118.32 150.624C118.32 158.4 125.616 160.448 143.648 165.936C163.488 171.424 185.168 178.048 185.168 202.512C185.168 225.36 166.24 240 138.864 240C120.592 240 101.2 233.6 87.0557 222.4Z"
        fill={COLORS.SECONDARY_300}
      />
      <path d="M232.944 80H205.552V240H232.944V80Z" fill={COLORS.SECONDARY_300} />
    </S.AvatarSvg>
  );
};

const getColorClasses = (color: SupportedColors): string => {
  switch (color) {
    case 'success':
      return 'bg-success-300 text-success-500';
    case 'warning':
      return 'bg-warning-300 text-warning-500';
    case 'danger':
      return 'bg-danger-300 text-danger-500';
    case 'primary':
      return 'bg-primary-300 text-primary-500';
    case 'secondary':
      return 'bg-secondary-300 text-secondary-700';
    case 'client':
      return 'bg-client-300 text-client-700';
    case 'agency':
      return 'bg-agency-300 text-agency-700';
    default:
      return 'bg-secondary-300 text-secondary-700';
  }
};
const defaultProps = {
  shape: 'circle',
  size: 'sm',
  className: undefined,
  fit: 'cover',
  shadow: true,
  border: true,
};
// eslint-disable-next-line sonarjs/cognitive-complexity
export const Avatar: FC<AvatarProps> = (props) => {
  const {
    variant,
    alt,
    className,
    shape,
    size,
    fit,
    src,
    avatarUiName,
    imgLoading,
    shadow,
    border,
    autoInitials = true,
  } = {
    ...defaultProps,
    ...props,
  };

  const [color, setColor] = useState<SupportedColors | null | undefined>(variant);
  const [imgSrc, setImgSrc] = useState<string | null>(src ?? null);

  useEffect(() => {
    if (variant != null) return;
    const random = new XORShift(hashString(alt ?? avatarUiName ?? ''));
    const randomColor = random.choice([...supportedColors]);
    setColor(randomColor);
  }, []);

  const hasImgSrc = imgSrc !== null;

  return (
    <>
      {!hasImgSrc && !avatarUiName && <DefaultIcon {...props} />}
      {!hasImgSrc && avatarUiName && (
        <S.AvatarDiv
          shadow={shadow}
          {...props}
          className={clsx(
            className,
            size ? `avatar-${size}` : null,
            shape ? `avatar-${shape}` : null,
            color ? getColorClasses(color) : null,
            shadow ? 'shadow-2' : border ? 'border border-secondary-200' : null,
            'avatar display-inline-block',
          )}>
          <span
            className={clsx(
              getInitialsSize(size as Size),
              'layout-fill layout-column layout-align-center-center bold',
            )}>
            {autoInitials ? getInitials(avatarUiName) : avatarUiName}
          </span>
        </S.AvatarDiv>
      )}

      {hasImgSrc && (
        <S.AvatarImg
          shadow={shadow}
          src={src}
          alt={alt}
          loading={imgLoading}
          {...props}
          className={clsx(
            className,
            'avatar display-inline-block bg-neutral-100',
            size ? `avatar-${size}` : null,
            shape ? `avatar-${shape}` : null,
            fit ? `avatar-${fit}` : null,
            shadow ? 'shadow-2' : border ? 'border border-secondary-200' : null,
          )}
          // TODO: find a better management, the event is not well taken (can loop)
          onError={(event) => {
            const el = event.currentTarget;
            event.preventDefault();
            el.onerror = null;
            setImgSrc(null);
          }}
        />
      )}
    </>
  );
};
