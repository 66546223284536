import styled from '@emotion/styled';
import { BREAKPOINTS, RADIUSES } from '@sortlist-frontend/design-system';
import Image from 'next/image';

type LogoProps = {
  invert?: string;
};

export const Logo = styled(Image)`
  object-fit: contain;
  max-height: 100%;
  opacity: 0.45;
  ${(props: LogoProps) =>
    props.invert === 'true'
      ? 'filter: invert(1);'
      : 'filter: invert(63%) sepia(3%) saturate(13%) hue-rotate(315deg) brightness(87%) contrast(94%);'}
`;

export const LogosWrapper = styled.section`
  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    transform: translateY(calc(50%));
  }
`;

export const LogosInnerWrapper = styled.div`
  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    border-radius: ${RADIUSES.xl};
  }
`;
