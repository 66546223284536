import { AccountCircleTwoTone } from '@mui/icons-material';
import { useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import Image from 'next/image';
import { FC } from 'react';

import { usePublicAppContext } from '_core/context/public-app-context';
import { Reviews } from '_core/repos/public-api.repo';
import { homeConfig } from '_features/home/home.config';
import { Experience } from '_features/home/types';

import { ExperienceCard } from './components/ExperienceCard';
import { getExperiences } from './constant';
import * as S from './styles';

export type ConnectWithTheRightAgencyProps = {
  reviews?: Reviews;
};

const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

export const ConnectWithTheRightAgency: FC<ConnectWithTheRightAgencyProps> = ({ reviews }) => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const { media } = useMediaQuery();
  const { getUrl } = usePublicAppContext();
  const experiences = getExperiences(t, getUrl);

  return (
    <section className="bg-client-100 pt-64">
      <header className="container-lg pb-64 pb-gt-sm-0 layout-column layout-align-gt-xs-center-center layout-align-start-start">
        <div className="rounded-xl text-client-500 bg-client-200 px-16 py-12 layout-row layout-align-center-center">
          <AccountCircleTwoTone className="text-client-500" />
          <span className="ml-16 medium small">{t('home:sections.connect.label_V2')}</span>
        </div>
        <h2 className={clsx(media.gtXs ? 'h1 text-center' : 'h3 text-left', 'bold mt-24 mb-12 px-64 px-xs-0')}>
          {t('home:sections.connect.title_V2')}
        </h2>
        <h3 className={clsx(media.gtXs ? 'text-center' : 'text-left', 'h6 text-secondary-500')}>
          {t('home:sections.connect.subtitle_V2')}
        </h3>
      </header>
      <S.ArrowsContainer className="hide show-gt-sm my-24 m-xs-0 layout-row layout-align-center-center gap-x-128">
        <Image
          src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/shapes/left-curved-arrow-down.svg`)}
          alt="Left curved arrow down"
          height={60}
          width={30}
          className="hide-xs mr-64"
        />
        <Image
          src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/shapes/arrow-down.svg`)}
          alt="Center arrow down"
          height={60}
          width={30}
          className="hide-xs"
        />
        <Image
          src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/shapes/right-curved-arrow-down.svg`)}
          alt="Right curved arrow down"
          height={60}
          width={30}
          className="hide-xs ml-64"
        />
      </S.ArrowsContainer>
      <div className="container-lg px-0 layout-row layout-wrap layout-align-gt-sm-center-stretch layout-align-center-center pb-64">
        {experiences.map((experience: Experience) => (
          <div
            key={experience.id}
            className="flex-xs-100 flex-sm-50 flex-gt-sm-33 p-16 layout-column layout-align-center-center">
            <ExperienceCard className="flex" experience={experience} />
          </div>
        ))}
      </div>
      <S.Footer isGtXs={media.gtXs} className="container-lg py-16 layout-row layout-align-center-center">
        {/* TODO: when we decide to use real values replace the hardcoded ones with reviews?.reviewsCount and reviews?.reviewsAverage */}
        {/* <TrustpilotWidget
          className="width-xs-100 rounded-lg p-24"
          reviewsCount={reviews?.reviewsCount}
          reviewsAverage={reviews?.reviewsAverage}
          hasTitle={true}
        /> */}
      </S.Footer>
    </section>
  );
};
