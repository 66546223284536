import { MediaAttachmentFactory } from '_backend/queries/shared';

export const showContentOfCurrentCard = (
  currentActiveCardIndex: number,
  numberOfCardsVisible: number,
  keyOfCardFromArray: number,
) => {
  const arrayFromNumber = Array.from(Array(numberOfCardsVisible)).map(function (x, i) {
    return i + 1;
  });

  const showNextCards = arrayFromNumber.find((value, key) => currentActiveCardIndex + value === keyOfCardFromArray);

  return (
    currentActiveCardIndex - 1 === keyOfCardFromArray || currentActiveCardIndex === keyOfCardFromArray || showNextCards
  );
};

export const getAgencyLogo = (logo: string | null) => {
  return logo ? MediaAttachmentFactory.fromUrl({ url: logo }) : null;
};
