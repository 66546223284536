import { useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';

import { usePublicAppContext } from '_core/context/public-app-context';
import { onImageError } from '_core/media/get-media.utils';
import { homeConfig } from '_features/home/home.config';
import * as S from '_features/home/sections/JoinAsAgency/styles';

export const JoinAsAgency = () => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);
  const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;
  const { media } = useMediaQuery();
  const { getUrl } = usePublicAppContext();

  return (
    <section className="bg-agency-200 py-64">
      <div className="container-lg layout-column layout-align-start-center">
        <header className="layout-column layout-align-start-start layout-align-gt-xs-center-center mb-48">
          <div className="bg-agency-300 py-8 px-16 rounded-xl layout-row layout-align-center-center text-agency-700">
            <S.StoreIcon />
            <span className="px-8 medium">{t('home:sections.join.subtitle_V2')}</span>
          </div>
          <h2 className={clsx(media.gtXs ? 'text-center' : 'text-left', 'h1 mt-16 bold')}>
            {t('home:sections.join.title_V2')}
          </h2>
        </header>
        <S.RoundedContainer className="layout-column layout-gt-xs-row mb-32 bg-agency-900">
          <div className="flex layout-column layout-align-center-start p-24 p-gt-sm-64 flex-order-1">
            <h3 className="h2 text-agency-200 bold mb-16">{t('home:sections.join.revenue')}</h3>
            <p className="text-agency-300"> {t('home:sections.join.description_V2')}</p>
            <a href={getUrl('providers')} className="text-primary-900 bg-neutral-100 p-16 rounded-lg mt-32">
              {t('home:sections.join.getListed')}
            </a>
          </div>
          <div className="p-8 layout-row layout-align-center-center flex flex-order-gt-xs-2">
            <div className="relative layout-row layout-align-center-center flex">
              <S.Img
                src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/home/join-providers.webp`, {
                  width: 512,
                })}
                alt={'Join providers'}
                onError={onImageError}
                width={490}
                height={420}
                className="rounded-xl "
              />
            </div>
          </div>
        </S.RoundedContainer>
      </div>
    </section>
  );
};
