import { Levels } from '_core/utils/public-links/_types';

export const createSortedLvlAfterPosition = (obj: Levels) => {
  const newArr = Object.keys(obj).map((value) => {
    return {
      [value]: obj[value]['undefined']
        ? obj[value]['undefined']['undefined'][0].position
        : obj[value]['null']['null'][0].position,
    };
  });

  return newArr?.sort((a, b) => {
    return a[Object.keys(a)[0]] - b[Object.keys(b)[0]];
  });
};
