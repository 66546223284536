import { Star } from '@mui/icons-material';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import Image from 'next/image';
import { CSSProperties, FC, memo } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { onImageError } from '_core/media/get-media.utils';
import { homeConfig } from '_features/home/home.config';

import * as S from './styles';

type HeaderProps = {
  className?: string;
};

// Cause it's homepage, let's add this for the above-the-fold images
// https://stackoverflow.com/questions/37906602/blurry-downscaled-images-in-chrome
const cssImageRendering: CSSProperties = {
  imageRendering: '-webkit-optimize-contrast',
};

export const Header: FC<HeaderProps> = memo(({ className }) => {
  const { t } = useTranslation(homeConfig.i18nNamespaces);

  const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

  return (
    <header className={clsx(className, 'overflow-hidden')} data-testid="home-header-agency-search">
      <div className="container-lg px-0 layout-gt-sm-row layout-column pt-64 pt-xs-32 pb-0 pb-gt-sm-64 flex">
        <div className="flex-sm-100 px-16 flex-gt-sm layout-align-center-start layout-column">
          <S.Headline className="serif bold mb-16">{t('home:sections.header.title')}</S.Headline>
          <p className="h5 text-secondary-500 mb-32 text-break-word">{t('home:sections.header.subTitle')}</p>
          <div className="layout-gt-sm-row layout-column layout-align-center-start">
            <BriefingButton
              page={'home'}
              cta={'hero'}
              size="lg"
              className="mr-16 mb-16 mb-gt-sm-0"
              buttonStyle="primary"
              buttonVariant="raised"
              label={
                <Trans i18nKey={'home:sections.header.getStarted'}>
                  Get advice - <span className="italic">it’s free!</span>.
                </Trans>
              }
            />
          </div>
        </div>
        <div className="layout-column px-16 layout-align-center-center flex hide show-gt-sm relative">
          <div className="layout-row">
            <S.PortraitCard className="agency layout-column layout-align-start-end">
              <Image
                style={cssImageRendering}
                src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/home/persona-client.webp`, {
                  width: 346,
                })}
                width={346}
                height={420}
                alt={t('home:sections.header.marketingManager')}
                onError={onImageError}
                className={'mb-16 bg-client-100 border-neutral-100 rounded-xl shadow-2'}
              />
              <S.PortraitTextLeft className="width-40 absolute layout-column">
                <span className="bold text-neutral-100">{t('home:sections.header.mark')}</span>
                <span className="small text-neutral-100">{t('home:sections.header.marketingManager')}</span>
              </S.PortraitTextLeft>
            </S.PortraitCard>
            <S.PortraitCard className="client layout-column layout-align-start-start">
              <Image
                style={cssImageRendering}
                src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/home/persona-agency.webp`, {
                  width: 346,
                })}
                width={346}
                height={420}
                alt={t('home:sections.header.agencyOwner')}
                onError={onImageError}
                className={'mb-16 bg-client-100 border-neutral-100 rounded-xl shadow-2'}
              />
              <S.PortraitTextRight className="absolute width-40">
                <span className="bold text-neutral-100">{t('home:sections.header.lilia')}</span>
                <span className="small text-neutral-100 pl-4">{t('home:sections.header.agencyOwner')}</span>
              </S.PortraitTextRight>
            </S.PortraitCard>
          </div>
          <S.FoundBox className="bg-neutral-100 shadow-2 absolute rounded-md text-center p-16">
            <div className="p-4">
              {Array.from(Array(5).keys(), (e) => (
                <Star key={`header-star-${e}`} className="text-warning-500" style={{ fontSize: 16 }} />
              ))}
            </div>
            <p className="lh-1">
              <Trans i18nKey={'home:sections.header.markFoundLilia_V2'}>
                <span className="bold">Mark</span>
                <span>found</span>
                <span className="bold">Lila</span>
                <span> to build his new website</span>
              </Trans>
            </p>
          </S.FoundBox>
        </div>
      </div>
    </header>
  );
});
