import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const Headline = styled.h1`
  font-size: 5rem;
  line-height: 5rem;
  word-break: break-word;

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    font-size: 4rem;
    line-height: 1;
  }

  @media only screen and (max-width: 400px) {
    font-size: 3.5rem;
    line-height: 1;
  }

  &:after {
    content: '.';
    color: ${COLORS.PRIMARY_500};
  }
`;

export const PortraitCard = styled.div`
  &:first-of-type {
    z-index: 0;
    position: relative;
  }
  &:last-of-type {
    z-index: 1;
    position: relative;
    transform: translate(-3.5rem, 4.5rem);
  }
  img {
    width: 21rem;
    height: 26rem;
    object-fit: cover;
    border-style: solid;
    border-width: 0.125rem;
  }
`;

export const PortraitTextLeft = styled.div`
  top: 6rem;
  left: 1rem;
`;

export const PortraitTextRight = styled.div`
  top: 14rem;
  left: 4rem;
`;

export const FoundBox = styled(Box)`
  left: 50%;
  transform: translateX(-50%);
  bottom: -2.5rem;
  z-index: 3;
`;
