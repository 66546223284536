import { AssignmentTwoTone, MenuBookTwoTone, SupportAgentTwoTone } from '@mui/icons-material';
import { TFunction } from '@sortlist-frontend/translation/ssr';

import { GetUrlType } from '_core/links/public-app-links';
import type { Experience } from '_features/home/types';

export const getExperiences = (t: TFunction, getUrl: GetUrlType): Experience[] => {
  return [
    {
      id: 'project',
      icon: <AssignmentTwoTone />,
      label: t('home:sections.connect.card1.pill'),
      title: t('home:sections.connect.card1.title_V2'),
      description: t('home:sections.connect.card1.description_V2'),
      hiwHref: getUrl('project.new'),
      ctaLabel: t('home:sections.connect.card1.button'),
      cta: 'how-it-works-post-a-project',
    },
    {
      id: 'explore',
      icon: <MenuBookTwoTone />,
      title: t('home:sections.connect.card2.title'),
      description: t('home:sections.connect.card2.description'),
      ctaUrl: () => (window.location.href = getUrl('agency.search')),
      ctaLabel: t('home:sections.connect.card2.button'),
    },
    {
      id: 'advice',
      icon: <SupportAgentTwoTone />,
      title: t('home:sections.connect.card3.title'),
      description: t('home:sections.connect.card3.description_V2'),
      hiwHref: getUrl('client.advisors'),
      ctaLabel: t('home:sections.connect.card3.button'),
      cta: 'how-it-works-advisors',
    },
  ];
};
