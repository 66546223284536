import dynamic from 'next/dynamic';

import { DesktopCatalog } from './DesktopCatalog';

type EmptyProps = Record<string, unknown>;

const MobileCatalogWrapper = dynamic<EmptyProps>(() => import('./MobileCatalog').then((mod) => mod.MobileCatalog), {
  ssr: false,
});

export const Catalog = () => {
  return (
    <>
      <DesktopCatalog />
      <MobileCatalogWrapper />
    </>
  );
};
