import { MediaProviderInfo, parseMediaProviderUrl } from '@sortlist-frontend/media';

export type MediaAttachment = {
  url: string;
  type: MediaProviderInfo['provider'] | null;
  key?: string | null;
  position?: number | null;
};

export class MediaAttachmentFactory {
  static fromUrl({ url, position }: { url: string; position?: MediaAttachment['position'] }): MediaAttachment {
    const urlWithProtocol = !/^https?:\/\//.test(url) ? `https://${url}` : url;

    if (urlWithProtocol === null) {
      return {
        type: null,
        url: urlWithProtocol,
        position,
      };
    }

    const mediaInfo = parseMediaProviderUrl(urlWithProtocol);

    if (mediaInfo === null) {
      return {
        type: null,
        url: urlWithProtocol,
        position,
      };
    }
    const { provider, key } = mediaInfo;

    return {
      type: provider,
      key,
      url: urlWithProtocol,
      position,
    };
  }
}
