import type { CompanyType } from '_data/index';

export const getCompanyLogos = (companies: CompanyType[], count = 6) => {
  const randomCompanies = (count: number) => {
    const shuffledCompany = companies?.sort(() => {
      const mathRandom = Math.random();
      return 0.5 - mathRandom;
    });
    return shuffledCompany.slice(0, count);
  };
  return randomCompanies(count);
};
