import { ImageSourceHelper } from '@sortlist-frontend/media';
import { isNonEmptyString, isValidUrl } from '@sortlist-frontend/utils';

import { MediaAttachment } from '_backend/queries/shared/media-attachment';

const defaultSquareImage = '/_img/default/default-image-square.svg';
const defaultPortraitImage = '/_img/default/default-image-portrait.svg';
const defaultLandscapeImage = '/_img/default/default-image-landscape.svg';

export const getGumletImg = (
  MediaAttachment: MediaAttachment | null | undefined,
  options: { preferredWidth?: number; mobile: boolean },
): string | undefined => {
  if (!MediaAttachment || !isNonEmptyString(MediaAttachment.key)) {
    return '';
  }
  const { mobile, preferredWidth = 2200 } = options;
  return ImageSourceHelper.fromUrl(MediaAttachment.url, {
    width: !mobile ? preferredWidth : preferredWidth > 500 ? 500 : preferredWidth,
  });
};

export const getThumbImage = (
  MediaAttachment: MediaAttachment | null | undefined,
  options?: {
    preferredWidth?: number;
    mobile?: boolean;
  },
): string | undefined => {
  if (!MediaAttachment || !isNonEmptyString(MediaAttachment.url) || !isValidUrl(MediaAttachment.url)) {
    return '';
  }
  const { preferredWidth = 500, mobile = false } = options ?? {};
  switch (MediaAttachment?.type) {
    case 's3':
      return MediaAttachment.key ? getGumletImg(MediaAttachment, { preferredWidth, mobile }) : '';
    case 'youtube':
    case 'vimeo':
      return `https://thumbnail.sortlist.com/${MediaAttachment.type}?url=${MediaAttachment.url}`;
    default:
      return MediaAttachment?.url;
  }
};

export const onImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
  event.preventDefault();
  if (event.currentTarget.width > event.currentTarget.height) {
    event.currentTarget.srcset = defaultLandscapeImage;
    event.currentTarget.src = defaultLandscapeImage;
  } else if (event.currentTarget.height < event.currentTarget.width) {
    event.currentTarget.srcset = defaultPortraitImage;
    event.currentTarget.src = defaultPortraitImage;
  } else {
    event.currentTarget.srcset = defaultSquareImage;
    event.currentTarget.src = defaultSquareImage;
  }
  event.currentTarget.onerror = null;
};

export const buildVideoSrcUrl = (key: string | null | undefined, type: 'vimeo' | 'youtube', origin: string) => {
  if (!key) return;
  return type === 'vimeo'
    ? `https://player.vimeo.com/video/${key}`
    : `https://www.youtube.com/embed/${key}?autoplay=0&origin=${origin}`;
};
