import styled from '@emotion/styled';

export const Divider = styled.div`
  display: flex;
  align-items: center;
`;
export const DividerLine = styled.hr`
  border: none;
  padding: 0;
  margin: 0;
`;
export const DividerChildren = styled.div`
  white-space: nowrap;
`;
