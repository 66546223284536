import styled from '@emotion/styled';

export const CardLabel = styled.span`
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0;
`;

export const Footer = styled.footer<{ isGtXs: boolean }>`
  margin-bottom: -4rem;
`;

export const ArrowsContainer = styled.div`
  height: 3.5rem;
`;
