import styled from '@emotion/styled';
import { StorefrontTwoTone } from '@mui/icons-material';
import Image from 'next/image';

export const StoreIcon = styled(StorefrontTwoTone)`
  font-size: 16px;
`;

export const RoundedContainer = styled.div`
  border-radius: 24px;
`;

export const Img = styled(Image)`
  object-fit: cover;
`;
