import { ArrowBack, ArrowForward, LabelTwoTone, VerifiedUserTwoTone } from '@mui/icons-material';
import { Avatar, Rating, useMediaQuery } from '@sortlist-frontend/design-system';
import { getFormattedRating } from '@sortlist-frontend/shared-components';
import { useIsVisible } from '@sortlist-frontend/utils';
import { FC, MutableRefObject, TransitionEvent, useEffect, useRef, useState } from 'react';

import { ClampLines } from '_components/common/ClampLines';
import { usePublicAppContext } from '_core/context/public-app-context';
import { useTracker } from '_core/hooks/use-tracker';
import { getGumletImg } from '_core/media/get-media.utils';
import { TrackingParamsCpc, useTrack } from '_core/repos/track.repo';
import { Agency } from '_types/public-api';

import * as S from './styles';
import { getAgencyLogo, showContentOfCurrentCard } from './utils';

export const AGENCY_CARD_MARGIN = 4;
export const AGENCY_CARD_WIDTH = 320 + AGENCY_CARD_MARGIN * 2;

const DIRECTIOM_TYPE = {
  next: 'NEXT',
  prev: 'PREV',
};

type SliderProps = {
  current: number;
  needTransition: boolean;
  isInTransition: boolean;
  direction?: string;
};

type Props = {
  agencies: Agency[];
  clonedBlocks: number;
};

export const AgenciesSlider: FC<Props> = (props) => {
  const { track } = useTracker();
  const { agencies, clonedBlocks } = props;
  const { getUrl } = usePublicAppContext();
  const { mutateAsync: internalTrack } = useTrack();
  const [sliderOptions, setSliderOptions] = useState<SliderProps>({
    current: clonedBlocks,
    needTransition: true,
    direction: '',
    isInTransition: false,
  });
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | undefined>();
  const cardsContainerRef = useRef(null);
  const { media } = useMediaQuery();
  const inViewport = useIsVisible(cardsContainerRef as unknown as MutableRefObject<Element>, '0px');
  const agenciesWidth = (agencies?.length || 1) * AGENCY_CARD_WIDTH;
  const [trackedAgencies, setTrackedAgencies] = useState<string[]>([]);

  const visibleCardsCount = Math.floor(window.innerWidth / AGENCY_CARD_WIDTH);

  const trackAgencyImpression = (agency: Agency['attributes']) => {
    if (trackedAgencies.includes(agency.slug)) return;

    track('agencyImpression', {
      agencyName: agency.name,
      agencyPlan: agency.visibility_plan,
      agencySlug: agency.slug,
      type: 'home',
    });
    setTrackedAgencies([...trackedAgencies, agency.slug]);
  };

  const validateNextSlide = () => {
    const index = sliderOptions.current + visibleCardsCount - 1;
    const newAgencyInView = agencies?.[index]?.attributes;
    if (newAgencyInView != null) trackAgencyImpression(newAgencyInView);

    if (sliderOptions.current === agencies?.length - clonedBlocks) {
      setSliderOptions({
        ...sliderOptions,
        needTransition: false,
        current: clonedBlocks,
        isInTransition: false,
      });
    } else {
      setSliderOptions({
        ...sliderOptions,
        isInTransition: false,
      });
    }
  };

  const validatePrevSlide = () => {
    trackAgencyImpression(agencies?.[sliderOptions.current]?.attributes);

    if (sliderOptions.current === clonedBlocks - 1) {
      setSliderOptions({
        ...sliderOptions,
        needTransition: false,
        current: (agencies?.length || 0) - (clonedBlocks + 1),
        isInTransition: false,
      });
    } else {
      setSliderOptions({
        ...sliderOptions,
        isInTransition: false,
      });
    }
  };

  const handleNext = (manualTriggered = false) => {
    if (manualTriggered) clearInterval(intervalId as ReturnType<typeof setInterval>);
    if (sliderOptions.isInTransition) return;

    setSliderOptions((sliderOptions) => {
      return {
        ...sliderOptions,
        needTransition: true,
        current: sliderOptions.current + 1,
        direction: DIRECTIOM_TYPE.next,
        isInTransition: true,
      };
    });
  };

  const handlePrev = (manualTriggered = false) => {
    if (manualTriggered) clearInterval(intervalId as ReturnType<typeof setInterval>);
    if (sliderOptions.isInTransition) return;
    setSliderOptions({
      ...sliderOptions,
      needTransition: true,
      current: sliderOptions.current - 1,
      direction: DIRECTIOM_TYPE.prev,
      isInTransition: true,
    });
  };

  const transLateVal = () => {
    return -(sliderOptions.current * AGENCY_CARD_WIDTH);
  };

  const sliderStyle = () => {
    if (sliderOptions.needTransition) {
      return {
        transform: `translateX(${transLateVal()}px)`,
        transition: 'transform 0.3s ease-in-out',
      };
    }

    return {
      transform: `translateX(${transLateVal()}px)`,
    };
  };

  const handleSliderTranslateEnd = (event: TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === 'transform') {
      switch (sliderOptions.direction) {
        case DIRECTIOM_TYPE.next:
          validateNextSlide();
          break;
        case DIRECTIOM_TYPE.prev:
          validatePrevSlide();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setSliderOptions({
      ...sliderOptions,
      current: clonedBlocks,
      needTransition: false,
    });
  }, [clonedBlocks]);

  useEffect(() => {
    if (inViewport) {
      const index = sliderOptions.current + visibleCardsCount;
      const agenciesInViewport = agencies.slice(sliderOptions.current, index);

      agenciesInViewport?.forEach((agency) => {
        trackAgencyImpression(agency.attributes);
      });

      const intervalId = setInterval(() => {
        handleNext();
      }, 3000);

      setIntervalId(intervalId);

      // clean up interval function
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [inViewport]);

  const trackAndOpenAgencyProfileOnClick = (agency: Agency['attributes'], index: number) => {
    const trackData: TrackingParamsCpc = {
      name: 'homeCardClicked',
      url: location.href,
      agency_external_id: agency.external_id,
      position: index,
      cta: 'agencySlider',
    };
    internalTrack(trackData).catch(() => {});

    window.open(getUrl('agency.profile', { slug: agency.slug }), '_blank');
  };

  return (
    <>
      <div ref={cardsContainerRef} className="py-16 overflow-hidden">
        <div
          className="layout-row layout-align-start-stretch"
          style={{ ...sliderStyle(), width: agenciesWidth }}
          onTransitionEnd={handleSliderTranslateEnd}>
          {agencies?.map((agencyData, key) => {
            const agency = agencyData.attributes;
            const rating =
              agency.reviews_count > 0 ? getFormattedRating(agency.reviews_rating_total / agency.reviews_count) : 0;

            return (
              <S.Box
                key={`${agency.slug}-agencies-section-${key}`}
                className={`overflow-hidden mr-${AGENCY_CARD_MARGIN} ml-${AGENCY_CARD_MARGIN} cursor-pointer shadow-2 rounded-xl display-inline-block text-center border border-secondary-300 bg-neutral-100 p-24`}
                onClick={() => trackAndOpenAgencyProfileOnClick(agency, key)}>
                {showContentOfCurrentCard(sliderOptions.current, clonedBlocks, key) && (
                  <>
                    <Avatar
                      fit="contain"
                      size="lg"
                      imgLoading="lazy"
                      src={getGumletImg(getAgencyLogo(agency.logo), {
                        preferredWidth: 160,
                        mobile: media.xs,
                      })}
                      alt={agency.name}
                      shape="square"
                    />

                    <h4 className="bold pt-24 pb-8">{agency.name}</h4>

                    {agency.tagline && (
                      <span
                        className="small bold pb-8 display-block"
                        dangerouslySetInnerHTML={{ __html: agency.tagline }}></span>
                    )}

                    <div className="mb-24 layout-row layout-align-center-center">
                      {rating > 0 && <span className="h6 bold pr-8">{rating}</span>}

                      <Rating
                        rate={
                          agency.reviews_count > 0
                            ? getFormattedRating(agency.reviews_rating_total / agency.reviews_count)
                            : 0
                        }
                        size={16}
                        className="flex-none"
                      />
                      <span className="ml-8 text-secondary-500 small">({agency.reviews_count || 0})</span>
                    </div>

                    <div className="pt-12 layout-row layout-align-center-center">
                      <div className="py-4 px-8 border border-secondary-300 layout-row layout-align-center-center mr-8 rounded-md small text-secondary-700">
                        <LabelTwoTone style={{ fontSize: 16 }} />
                        <span className="pl-4">Promoted</span>
                      </div>
                      <div className="py-4 px-8 border border-secondary-300 layout-row layout-align-center-center ml-8 rounded-md small text-secondary-700">
                        <VerifiedUserTwoTone style={{ fontSize: 16 }} />
                        <span className="pl-4">Verified</span>
                      </div>
                    </div>

                    <div className="text-secondary-500 small pt-16">
                      <ClampLines
                        showMoreLessText={false}
                        text={agency.description as string}
                        className="lh-2"
                        sanitizeCollapsedText={true}
                      />
                    </div>
                  </>
                )}
              </S.Box>
            );
          })}
        </div>
      </div>
      <div className="layout-row layout-align-center-center pt-32">
        {Array.from(Array(2), (e: number, index: number) => (
          <div className="p-8" key={index}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="rounded layout-column layout-align-center-center text-neutral-100 bg-secondary-900 p-8"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (index === 0) {
                  handlePrev(true);
                } else {
                  handleNext(true);
                }
              }}>
              {index === 0 ? <ArrowBack style={{ fontSize: 24 }} /> : <ArrowForward style={{ fontSize: 24 }} />}
            </a>
          </div>
        ))}
      </div>
    </>
  );
};
