import type { FC, ReactElement } from 'react';

import type { Position, Size } from '../types';
import * as S from './styles';

export type DividerProps = {
  size?: Size;
  /**
   * Example: secondary-500, secondary-700, primary-300, etc...
   */
  color?: string;
  className?: string;
  /**
   * Align the children (icon, text) - check stories
   */
  childrenPosition?: Position;
  children?: ReactElement | string;
};

const defaultProps = {
  size: 'xs',
  color: 'secondary-300',
  childrenPosition: 'center',
};

export const Divider: FC<DividerProps> = (props: DividerProps) => {
  const { size, color, childrenPosition, children, className } = {
    ...defaultProps,
    ...props,
  };
  const sizeClass = size ? `divider-${size}` : 'divider-xs';
  const colorClass = color ? `bg-${color}` : 'bg-secondary-300';
  const childrenClass = `${sizeClass} ${colorClass}`;

  const renderChildren = () => {
    switch (childrenPosition) {
      case 'left':
        return (
          <S.Divider className={`${className ? className : ''} divider`}>
            <S.DividerChildren className="pr-16 dividerChildren">{children}</S.DividerChildren>
            <S.DividerLine className={`dividerLine flex ${childrenClass} `} />
          </S.Divider>
        );
      case 'right':
        return (
          <S.Divider className={`${className ? className : ''} divider`}>
            <S.DividerLine className={`dividerLine flex ${childrenClass} `} />
            <S.DividerChildren className="pl-16 dividerChildren">{children}</S.DividerChildren>
          </S.Divider>
        );
      case 'center':
      default:
        return (
          <S.Divider className={`${className ? className : ''} divider`}>
            <S.DividerLine className={`dividerLine flex ${childrenClass} `} />
            <S.DividerChildren className="px-16 dividerChildren">{children}</S.DividerChildren>
            <S.DividerLine className={`dividerLine flex ${childrenClass} `} />
          </S.Divider>
        );
    }
  };

  return (
    <>
      {!children && <S.DividerLine className={`${className ? className : ''} dividerLine ${childrenClass} `} />}
      {children && renderChildren()}
    </>
  );
};
