import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const CatalogContainer = styled.div`
  justify-content: center;
`;

export const Anchor = styled.a`
  color: ${COLORS.SECONDARY_700};

  svg {
    color: ${COLORS.SECONDARY_300};
  }

  &:hover {
    color: ${COLORS.PRIMARY_500};

    svg {
      color: ${COLORS.PRIMARY_500};
    }
  }
`;
