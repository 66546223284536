import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS } from '../../variables';
import { AVATAR_FITS, AVATAR_SHAPES, AVATAR_SIZES } from './constants';
import type { AvatarProps } from './index';

const generateSizeStyles = (): string => {
  let style = '';
  Object.keys(AVATAR_SIZES).forEach((element) => {
    style += `&.avatar-${element} {
                width: ${AVATAR_SIZES[element]};
                height: ${AVATAR_SIZES[element]};
                min-width: ${AVATAR_SIZES[element]};
                min-height: ${AVATAR_SIZES[element]};
                max-width: ${AVATAR_SIZES[element]};
                max-height: ${AVATAR_SIZES[element]};
            }`;
  });
  return style;
};

const generateSizeShapes = (): string => {
  let style = '';
  Object.keys(AVATAR_SHAPES).forEach((element) => {
    style += `&.avatar-${element} {
                border-radius: ${AVATAR_SHAPES[element]};
            }`;
  });
  return style;
};

const generateSizeFits = (): string => {
  let style = '';
  Object.keys(AVATAR_FITS).forEach((element) => {
    style += `&.avatar-${element} {
                object-fit: ${AVATAR_FITS[element]};
                &:after {
                    background-size: ${AVATAR_FITS[element]};
                }
            }`;
  });
  return style;
};

const generateDefaultStyles = (shadow?: boolean) => {
  let style = '';
  const borderStyle = shadow
    ? `border: 2px solid ${COLORS.NEUTRAL_100};`
    : `border: 1px solid ${COLORS.SECONDARY_300};`;
  style += `${borderStyle}
    outline: none;
    border-image: none;
    position: relative;
    overflow: hidden;
    `;
  return style;
};

const sharedStyle = (shadow?: boolean) => {
  return css`
    ${generateDefaultStyles(shadow)}
    ${generateSizeStyles()}
    ${generateSizeShapes()}
    ${generateSizeFits()}
  `;
};

export const AvatarImg = styled.img`
  ${(props: AvatarProps) => `
    ${sharedStyle(props.shadow).styles};
  `}
`;

export const AvatarSvg = styled.svg`
  ${(props: AvatarProps) => `
    ${sharedStyle(props.shadow).styles};
  `}
`;

export const AvatarDiv = styled.div`
  ${(props: AvatarProps) => `
    ${sharedStyle(props.shadow).styles};
  `}
`;
