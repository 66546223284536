import styled from '@emotion/styled';

export const RoundedContainer = styled.div`
  border-radius: 1.5rem;
`;

export const Rating = styled.span`
  font-size: 3.25rem;
`;

export const TrustpilotLogo = styled.img`
  width: 9rem;
  height: 2rem;
  object-fit: contain;
`;
