export const getAggregateRating = (ratingValue: string, reviewCount: number) => `{
  "@type":"AggregateRating",
  "itemReviewed":{
    "@type":"Organization",
    "name":"Sortlist"
  },
  "bestRating": "5",
  "ratingValue": "${Number(ratingValue) * 5}",
  "reviewCount": "${reviewCount}"
}`;
