import styled from '@emotion/styled';

export const CardTitle = styled.h4`
  font-size: 1.75rem;
`;

export const CardLabel = styled.span`
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 0;
`;
