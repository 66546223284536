import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const Box = styled.div`
  width: 20rem;
  height: 27.5rem;
  transition: 0.3s ease-in-out;

  &:hover {
    border-color: ${COLORS.PRIMARY_500};
  }
`;
