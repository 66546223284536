import {
  BoltTwoTone,
  Check,
  Close,
  EmojiObjectsTwoTone,
  EmojiPeopleTwoTone,
  HandshakeTwoTone,
} from '@mui/icons-material';
import { Divider, useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';
import Image from 'next/image';
import { FC } from 'react';

import { BriefingButton } from '_components/Briefing/versions/NormalBriefing/button';
import { usePublicAppContext } from '_core/context/public-app-context';
import { onImageError } from '_core/media/get-media.utils';
import { Reviews } from '_core/repos/public-api.repo';
import * as S from '_features/home/sections/Customers/styles';

export type CustomersProps = {
  reviews?: Reviews;
};

export const Customers: FC<CustomersProps> = ({ reviews }) => {
  const { t } = useTranslation(['home', 'common']);
  const { getUrl } = usePublicAppContext();
  const { media } = useMediaQuery();

  const s3BucketUrl = process.env.NEXT_PUBLIC_S3_BUCKET;

  return (
    <section className="py-64">
      <header className="container-lg layout-column layout-align-start-start layout-align-gt-sm-center-center mb-16">
        <div className="layout-row layout-align-center-center width-100">
          <Image
            src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/home/customers-avatars.webp`, { width: 200 })}
            alt={'Customers avatars'}
            onError={onImageError}
            width={200}
            height={92}
          />
        </div>
        <h2 className={clsx(media.gtXs ? 'h1 text-center' : 'h3 text-left', 'bold mb-16')}>
          {t('home:sections.customers.title')}
        </h2>
        <h3 className={clsx(media.gtXs ? 'text-center' : 'text-left', 'h6 text-secondary-500 mb-32')}>
          {t('home:sections.customers.subtitle')}
        </h3>
      </header>
      <div className="container-lg px-8 layout-row layout-wrap layout-align-start-stretch">
        <div className="flex-gt-sm-50 layout-column flex-order-1 flex-order-gt-sm-1 p-8">
          <S.RoundedContainer className="bg-client-100 flex layout-column layout-align-space-between px-32 py-64">
            <header className="layout-column layout-align-center-start text-left">
              <EmojiObjectsTwoTone
                className="text-client-500 bg-client-200 p-8 rounded-lg"
                style={{ fontSize: '2.5rem' }}
              />
              <p className="h3 bold my-16">{t('home:sections.customers.ai.title')}</p>
              <p className="h6 text-secondary-500 lh-2">{t('home:sections.customers.ai.description_V2')}</p>
            </header>
            <div className="layout-column layout-align-start-start mt-64">
              <span className="h6 text-secondary-500">
                <Trans i18nKey={'home:sections.customers.ai.providers_V2'}>
                  <b className="text-secondary-900">80,000</b> providers registered
                </Trans>
              </span>
              <span className="h6 text-secondary-500 my-16">
                <Trans i18nKey={'home:sections.customers.ai.reviews_V2'}>
                  <b className="text-secondary-900">31,282</b> reviews from verified clients
                </Trans>
              </span>
              <span className="h6 text-secondary-500">
                <Trans i18nKey={'home:sections.customers.ai.works'}>
                  <b className="text-secondary-900">170k+</b> works uploaded by agencies
                </Trans>
              </span>
            </div>
          </S.RoundedContainer>
        </div>
        <div className="flex-gt-sm-50 layout-column flex-order-3 flex-order-gt-sm-2 p-8">
          <S.RoundedContainer className="flex bg-secondary-100 layout-column layout-align-space-between px-32 py-64">
            <header className="layout-column layout-align-center-start text-left">
              <HandshakeTwoTone
                className="text-client-500 bg-client-200 p-8 rounded-lg"
                style={{ fontSize: '2.5rem' }}
              />
              <p className="h3 bold my-16">{t('home:sections.customers.efficient.title')}</p>
              <p className="h6 text-secondary-500 lh-2">{t('home:sections.customers.efficient.description_V2')}</p>
            </header>
            <div className="layout-column layout-align-start-start mt-64">
              {/* <div className="layout-row mt-16">
                <span className="h6 mr-16">
                  <S.Rating className="bold">{Number(reviews?.reviewsAverage) * 5}</S.Rating>/5
                </span>
                <S.TrustpilotLogo
                  src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/logos/companies/trustpilot-black.svg`)}
                  alt="trustpilot-black"
                  onError={onImageError}
                />
              </div> */}
              <span className="h6 text-secondary-500 mt-16">
                <Trans i18nKey={'home:sections.customers.efficient.stories'}>
                  <b className="text-secondary-900">150k+</b> business stories started on Sortlist
                </Trans>
              </span>
            </div>
          </S.RoundedContainer>
        </div>
        <div className="flex-gt-sm-50 layout-column flex-order-2 flex-order-gt-sm-3 p-8">
          <S.RoundedContainer className="flex bg-secondary-100 layout-column layout-align-space-between py-64">
            <div className="layout-column layout-align-center-start text-left px-32">
              <BoltTwoTone className="text-client-500 bg-client-200 p-8 rounded-lg" style={{ fontSize: '2.5rem' }} />
              <p className="bold my-16 h3">{t('home:sections.customers.stories.title')}</p>
              <p className="text-secondary-500 h6 lh-2">{t('home:sections.customers.stories.description_V2')}</p>
            </div>
            <footer className="layout-gt-sm-row layout-column layout-align-space-between mt-64">
              <div className="flex px-32 py-32 py-gt-sm-0">
                <Check className="text-success-500" style={{ fontSize: '2rem' }} />
                <p className="bold h6 my-8">{t('home:sections.customers.stories.with')}</p>
                <p className="text-success-500 p">{t('home:sections.customers.stories.10min')}</p>
              </div>
              <Divider className="mx-32 mx-gt-sm-0" />
              <div className="flex px-32 py-32 py-gt-sm-0">
                <Close className="text-danger-500" style={{ fontSize: '2rem' }} />
                <p className="bold h6 my-8">{t('home:sections.customers.stories.without')}</p>
                <p className="text-danger-500 p">{t('home:sections.customers.stories.weeks')}</p>
              </div>
            </footer>
          </S.RoundedContainer>
        </div>
        <div className="flex-gt-sm-50 layout-column flex-order-4 p-8">
          <S.RoundedContainer className="flex bg-client-100 layout-column layout-align-space-between px-32 py-64">
            <header className="layout-column layout-align-center-start text-left">
              <EmojiPeopleTwoTone className="text-client-500 bg-client-200 p-8 rounded-lg" style={{ fontSize: 40 }} />
              <p className="h3 bold my-16">{t('home:sections.customers.human.title')}</p>
              <p className="h6 text-secondary-500 lh-2">{t('home:sections.customers.human.description_V2')}</p>
            </header>
            <footer className="layout-column layout-align-start-start mt-64">
              <div className="layout-row layout-align-center-center">
                <Image
                  src={ImageSourceHelper.fromUrl(`${s3BucketUrl}/illustrations/home/reviews-avatars.webp`, {
                    width: 120,
                  })}
                  alt={'Reviews avatars'}
                  onError={onImageError}
                  width={120}
                  height={65}
                  style={{ maxWidth: 120, marginLeft: -16 }}
                />
              </div>
              <span className="h6 text-secondary-500 mt-4">
                <Trans i18nKey={'home:sections.customers.human.advisors'}>
                  <b className="text-secondary-900">50+</b> advisors at Sortlist
                </Trans>
              </span>
            </footer>
          </S.RoundedContainer>
        </div>
        <div className="layout-column p-8 flex-order-5 flex-100">
          <S.RoundedContainer
            className={clsx(
              { 'bg-secondary-100': media.gtXs },
              'py-64 px-32 px-gt-sm-64 flex  layout-align-center-center layout-column',
            )}>
            <p className={clsx(media.gtXs ? 'h3' : 'h6 lh-1', 'bold mb-16 text-center')}>
              {t('home:sections.customers.cta.title')}
            </p>
            <div className="layout-column layout-gt-xs-row layout-align-start-stretch layout-align-gt-xs-center-center">
              <BriefingButton
                page={'home'}
                cta={'solutions'}
                className="flex-xs-100"
                buttonStyle="primary"
                buttonVariant="raised"
                label={t('home:sections.customers.cta.project')}
                size="lg"
              />
              <span className="m-16 text-secondary-500 hide-xs">{t('common:general:or' as any)}</span>
              <a href={getUrl('agency.search')} className="btn btn-light btn-primary btn-lg flex-xs-100 mt-xs-16">
                {t('home:sections.customers.cta.agencies_V2')}
              </a>
            </div>
          </S.RoundedContainer>
        </div>
      </div>
    </section>
  );
};
