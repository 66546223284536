import type { Shape, Size } from '../types';

export const getInitials = (fullName: string): string | null => {
  if (typeof fullName !== 'string' || fullName.trim().length < 1) {
    return null;
  }
  const name = fullName.trim().replace(/\s{2,}/g, ' ');
  const parts = name.split(' ');
  let initials: string;
  if (parts.length === 1) {
    initials = parts[0].charAt(0) + (parts[0].charAt(1) ?? parts[0].charAt(0));
  } else {
    initials = parts[0].charAt(0) + parts[1].charAt(0);
  }
  return initials.toUpperCase();
};

export const getInitialsSize = (size?: Size): string | null => {
  switch (size) {
    case 'xs':
      return 'small';
    case 'sm':
      return 'p';
    case 'md':
      return 'h5';
    case 'lg':
      return 'h3';
    default:
      return 'p';
  }
};

export function hashString(value: string) {
  let hash = 5381;
  for (let i = 0; i < value.length; i++) {
    hash = (hash * 33) ^ value.charCodeAt(i);
  }
  return hash >>> 0;
}

export const sizes: Size[] = ['xs', 'sm', 'md', 'lg'];
export const avatarShapes: Shape[] = ['square', 'circle'];
