import Head from 'next/head';

import { usePublicAppContext } from '_core/context/public-app-context';
import type { Reviews } from '_core/repos/public-api.repo';
import { getAggregateRating } from '_features/longtail/seo/json-ld/aggregate-rating';

type Props = {
  reviews: Reviews;
};

export const HomeJsonLd: React.FC<Props> = (props) => {
  const { reviews } = props;
  const { domainInfo, locale } = usePublicAppContext();
  const origin = domainInfo?.getLocalizedUrl(locale);

  const ORG_SCHEMA = `{
    "@context":"https://schema.org",
    "@type":"WebSite",
    "@id":"${origin}#search",
    "url":"${origin}",
    "potentialAction":{
      "@type":"SearchAction",
      "target":"${origin}/search?query=%5B%7B%22name%22%3A%22{search_term_string}%22%7D%5D",
      "query-input":"required name=search_term_string"
  },
   "@graph":[{
     "@type":"Corporation",
     "@id":"${origin}#corporation",
     "name":"Sortlist",
     "legalname":"Sortlist SA",
     "logo":"${origin}/_/images/logos/logotype-secondary.svg?c=v2021.35.1",
     "url":"${origin}",
     "sameAs":[
       "https://www.facebook.com/wearesortlist",
       "https://instagram.com/sortlist",
       "https://www.linkedin.com/company/sortli-st/",
       "https://www.youtube.com/sortlist"
     ],
     "address":{
       "@type":"PostalAddress",
       "addressLocality":" Wavre, Belgium",
       "postalCode":"1301",
       "streetAddress":"29 Avenue Zénobe Gramme"
     },
     "contactPoint":[
       {
         "@type":"ContactPoint",
         "telephone":"+32 2 808 14 22",
         "contactType":"customer service",
         "areaServed":"BE",
         "availableLanguage":["French","English","Dutch"]
       },
       {
         "@type":"ContactPoint",
         "telephone":"+32 2 808 14 22",
         "contactType":"customer service",
         "areaServed":"BE",
         "availableLanguage":["French","English","Dutch"]
       },
       {
         "@type":"ContactPoint",
         "telephone":"+33 7 56 79 82 23",
         "contactType":"customer service",
         "areaServed":"FR",
         "availableLanguage":["French","English"]
       },
       {
         "@type":"ContactPoint",
         "telephone":"+34 91 198 96 86",
         "contactType":"customer service",
         "areaServed":"ES",
        "availableLanguage":["French","English","Spanish"]
       },
       {
         "@type":"ContactPoint",
         "telephone":"+31 10 808 0733",
         "contactType":"customer service",
         "areaServed":"NL",
         "availableLanguage":["French","English","Dutch"]
      },
     {
         "@type":"ContactPoint",
         "telephone":"+49 89 1208 5167",
         "contactType":"customer service",
         "areaServed":"DE",
         "availableLanguage":["German","English"]
      }
     ]
     },
     {
       "@type":"WebPage",
       "@id":"${origin}#info",
       "description":"Discover the most relevant agencies for your project based on your own specific requirements.",
       "lastReviewed":"2021-05-26T05:59:02.085Z",
       "reviewedBy":{
         "@type":"Organization",
         "name":"Sortlist"
       }
     },
     {
       "@type":"Brand",
       "@id":"${origin}#brand",
       "slogan":"Find the right agency!.",
       "logo": "${origin}/_/images/logos/logotype-secondary.svg?c=v2021.35.1",
       "aggregateRating":${getAggregateRating(reviews.reviewsAverage, reviews.reviewsCount)}
     }
   ]}`;

  return (
    <Head>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ORG_SCHEMA }} />
    </Head>
  );
};
