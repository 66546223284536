import { isNonEmptyString } from '@sortlist-frontend/utils';

import { MediaProviderInfo } from '../types';
import { S3UrlParser } from './s3-url-parser';
import { VideoUrlParser } from './video-url-parser';

/**
 * A, utility function to extract provider/media information (s3, youtube, vimeo) from a given url
 */
export const parseMediaProviderUrl = (url: string): MediaProviderInfo | null => {
  if (!isNonEmptyString(url)) {
    return null;
  }
  const s3Object = S3UrlParser.parse(url);
  if (s3Object !== null) {
    return {
      provider: 's3',
      key: s3Object.key,
    };
  }
  const videoObject = VideoUrlParser.parse(url);
  if (videoObject !== null) {
    return {
      provider: videoObject.provider,
      mediaType: 'video',
      key: videoObject.key,
    };
  }
  return null;
};
